/**
 * All the styles related with the sidebar of the application.
 * The file is being loaded in app.scss
 */


/* Sidebar. */
/* =============================================================== */
.sidebar .sidebar-brand {
    padding: 0px;
}
.toggled .sidebar-brand-icon img  {
    width: 100%;
    height: auto;
}
@media only screen and (max-width: 768px) {
    .sidebar .sidebar-brand {
        padding: 0px;
    }
    .sidebar-brand-icon img  {
        width: 100%;
        height: auto;
    }
}