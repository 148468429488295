/**
 * All the styles related with the application.
 * The file is being loaded in app.scss
 */

/* General */
/* =============================================================== */
html {
    scroll-behavior: smooth;
}

.alert-info {
    background-color: #6b838f !important;
    border-color: #bce8f1 !important;
}

.btn-link {
    font-weight: 400;
    color: #212121 !important;
    text-decoration: none;
  }

/* Colors */
/* =============================================================== */
.bg-dark, .btn-dark {
    background-color: #212121 !important;
}

/* Pagination */
/* =============================================================== */
.pagination {
   justify-content: center;
}

/* Badges */
/* =============================================================== */
.badge {
    text-transform: capitalize;
    color: #fff;
    font-weight: 600;
    letter-spacing: .5px;
    vertical-align: middle;
}

/* Gold button. */
/* =============================================================== */
.btn-gold {
    background-color: #c99f50;
    color: #fff;
    padding: 0.8rem 1.5rem;
    &:hover {
        background-color: #fff;
        border-color: #c99f50;
        color: #c99f50;
    }
}

/* Labels. */
/* =============================================================== */
label.required {
    &:after {
        color: $danger;
        content: ' *';
    }
}

@media only screen and (max-width: 768px) {
  .svg-img { 
      width: 80% !important;
  }
  .sidebar-brand-icon img {
      margin-top: 85px;
  }
  .fc-toolbar {
    font-size: 0.75em;
  }
}

@media screen and (min-width: 480px) {
  .dashboard-calendar {
    height:620px; 
    overflow-y: auto;
  }
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .clients-table tr:hover { 
    color: #fff;
    background: #1687ff; 
}

  .clients-table {
    max-height: 800px;
    overflow-y: auto;
  }

  .dashboard-card {
    max-height: 350px;
    overflow-y: auto;
  }

  /**
   * FilePond Custom Styles
 */

.filepond--drop-label {
  color: #4c4e53;
}

.filepond--label-action {
  -webkit-text-decoration-color: #babdc0;
  text-decoration-color: #babdc0;
}

.filepond--panel-root {
  border-radius: 2em;
  background-color: #edf0f4;
}

.filepond--item-panel {
  background-color: #595e68;
}

.filepond--drip-blob {
  background-color: #7f8a9a;
}

table tbody tr .actions a,
table tbody tr .actions form {
  display: inline-block !important;
}

.fc-scroller {height:100% !important;}
.fc-time-grid .fc-content-col {
  padding-left: 7px;
  padding-right: 7px;
}

.dashboard-fields {
  height: 100px !important;
}

.box {
  height: 200px !important;
}

.add-box {
  display: inline-block;
  float:right;
  margin:10px 20px 0 0;
  position: absolute;
  top: 0;
  right: 0;
}
