/**
 * All the styles related with the select2 plugin.
 * The file is being loaded in app.scss
 */

.select2-selection {
    border: 1px solid #ced4da !important;
}
.select2-selection--single {
    @extend .custom-select;
}
.select2-container--open .select2-dropdown {
    margin-top: 5px;
    border: none;
    box-shadow: 0 7px 14px 0 rgba(60,66,87,.08), 0 3px 6px 0 rgba(0,0,0,.12);
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $primary;
    color: white;
}
.select2-selection__arrow {
    display: none;
}
.select2-container--default .select2-selection--single {
    height: 37.03px;
    padding: 0.25rem 1.75rem 0.375rem 0.75rem;
}
.select2-search--dropdown {
    padding: 0px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    margin: 0px;
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
    color: #3c4257;
    border: none;
    border-bottom: 1px solid #e3e8ee;
    &:focus {
        border-color: none;
        outline: 0;
    }
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #3c4257 !important;
    padding: 0px;
    font-weight: bold;
}
.is-invalid + .select2-container .select2-selection--single {
    border-color: #dc3545 !important;
}
.is-invalid + .select2-container .select2-selection--multiple {
    border-color: #dc3545 !important;
}

.select2-container--default .select2-search--inline .select2-search__field {
    padding-left: 8px !important;
}
