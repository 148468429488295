/* Breadcrumb. */
/* =============================================================== */
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding-top: 26px !important;
    font-size: large !important;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 0 !important;
    background-color: transparent !important;
    color: #6E707E;
}

.breadcrumb-item {
    color: #6E707E !important;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6E707E;
    font-weight: bold;
    content: "/" !important;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
}
.breadcrumb-item.active {
    color: grey;
}