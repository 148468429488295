/* Colors */
/* =============================================================== */
.bg-dark {
    background-color: #0f1b2f !important;
}

/* Btn primary. */
/* =============================================================== */
.btn-primary {
    background-color: #0275d8 !important;
}

.primary-link {
    color: #0275d8 !important;
}

/* Pagination */
/* =============================================================== */
.pagination {
   justify-content: center;
}

/* Badges */
/* =============================================================== */
.badge {
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    letter-spacing: .5px;
    vertical-align: middle;
}

/* Tooltip */
/* =============================================================== */
[data-toggle='tooltip'] {
    cursor: default;
    border-bottom: 1px dotted;
}

/* Underline */
/* =============================================================== */
.custom-underline {
    background-image: linear-gradient(90deg, #F34FB5, #958AF1);
    background-position: bottom;
    background-size: 100% 10%;
    background-repeat: no-repeat;
}

/* Auth */
/* =============================================================== */

.header {
    display: block;
}

.bg-gradient-primary{
    background: linear-gradient(87deg, #ffffff 0, #9fafb9 100%) !important;
}

.fill-default{
    fill: #0f1b2f;
}

.separator-skew{
    height: 60px;
}

@media (min-width: 1200px){
    .separator-skew{
        height: 70px;
    }
}

.separator-bottom{
    top: auto;
    bottom: 0;
}

.separator-bottom svg{
    bottom: 0;
}

.auth-body {
    background-color: #0f1b2f;
}

@media only screen and (max-width: 768px) {
    .mobile-margin { 
        padding-top: 20px !important;
    } 
    .auth-header {
        display: none;
    }
    .img-fluid {
        border-radius: 0% !important;
        padding-bottom: 85px;
    }
}
