/**
 * All the styles related with the tables of the application.
 * The file is being loaded in app.scss
 */

/* General. */
/* =============================================================== */
.table-title {
    font-size: 28px;
    font-weight: bold;
    margin: 0;
}
.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
    background-color: #E9ECF3;
}  
