/**
 * All the styles related with the flatpickr of the application.
 * The file is being loaded in app.scss
 */

/* Label Required */
/* =============================================================== */
.flatpickr {
    background-color: #fff !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    &:hover {
        cursor: pointer;
    }
}
.clear-flatpickr {
    background-color: #fff !important;
    border-radius: 0.25rem !important;
    border: 1px solid #ced4da !important;
    box-shadow: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    text-shadow: none !important;
    i {
        line-height: 1.6 !important;
        color: #495057 !important;
    }
    &:hover {
        background-color: #eee !important;
    }
}
