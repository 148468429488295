@import url('https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i');

@import 'variables';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import '~bootstrap/scss/bootstrap';

$theme-colors: ( "primary":#0d1c36);

@import '~flatpickr/dist/themes/airbnb';

@import '~select2/dist/css/select2';

@import 'auth';
@import 'flatpickr';
@import 'select2';
@import 'sidebar';
@import 'general';
@import 'reg_plates';
@import 'tables';
@import 'cards';
@import 'breadcrumb';
@import 'search_bar';
