/* Reg Plates. */
/* =============================================================== */
@media only screen and (min-width: 768px) {

    .reg-plates {
        background-color: white;
        padding: 0.35em 1em .25em 2.2em;
        font-weight: bold;
        color:black;
        font-size: 1.2em;
        border-radius: 5px;
        border: 1px solid #000;
        box-shadow: 1px 1px 1px #ddd;
        position: relative;
        font-family: helvetica, ariel, sans-serif;
    }
    
    .reg-plates:before {
        content: 'CY';
        // padding-left: 8px;
        display: block;
        width: 30px;
        height: 100%;
        background: #063298;
        position: absolute;
        top: 0;
        left: 0;
        color: #ddd;
        border-radius: 5px 0 0 5px;
        font-size: .4em;
        line-height: 55px;
    }
    
    .reg-plates:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 7px;
        width: 15px;
        height: 15px;
        border-radius: 30px;
        border: 1px dashed #f8d038;
    }
    
    .reg-plates.pleft:before {
        padding-left: 8px !important;
    }
}