/**
 * All the styles related with the cards of the application.
 * The file is being loaded in app.scss
 */

/* General. */
/* =============================================================== */
 .card {

    .card-header[data-toggle=collapse] {
        cursor: pointer;
    }

}

.card-scroll {
    min-height: 350px;
    max-height: 350px;
    overflow-y: scroll;
}
